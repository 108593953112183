import { tokenRequest } from './api/auth';
import axios from 'axios';
import { headerWithLang } from './api/lang';
import passData from "@/services/passData.js";

const PUBLIC_URL = process.env.VUE_APP_API_BASE_URL;
const BASE_URL = PUBLIC_URL + '/tenant';
const upSideAuthRequest = axios.create({
    baseURL: BASE_URL,
    timeout: 30000,
    headers: headerWithLang(),
  });
class UpSideService {
    constructor(tenant, token,user) {
        this.tenant_name = tenant;
        this.userData = user;
        const refreshToken = () => {
            //debugger;
            if(!this.userData || !this.userData['refresh']){
                passData.$emit("UsideUnauthorized");
                return Promise.reject();
            }
            const refreshBody = { refresh: this.userData['refresh'] };
            const tenant = this.tenant_name;
            const refreshURL = `/${tenant}/api/token/refresh`;
            return tokenRequest.post(refreshURL, refreshBody)
              .then((response) => {
                passData.$emit("UsideUnauthorized");
                return Promise.resolve(response.data);
              }).catch((error) => Promise.reject(error));
        };
        const isCorrectRefreshError = (status) => status === 401;
        const errorInterceptor = (error) => {
            const originalRequest = error.config;
            if (error.response != null) {
              const { status } = error.response;
              if (isCorrectRefreshError(status)) {
                return refreshToken().then(() => {
                  const headerAuthorization = `Bearer ${token}`;
                  upSideAuthRequest.defaults.headers.Authorization = headerAuthorization;
                  originalRequest.headers.Authorization = headerAuthorization;
                  return upSideAuthRequest(originalRequest);
                }).catch((tokenRefreshError) => {
                  return Promise.reject(tokenRefreshError);
                });
              }  
            }
            return Promise.reject(error);
        };
        upSideAuthRequest.interceptors.request.use(
            function (config) {
                const requestURL = config.url.charAt(0) == '/' ? config.url.slice(1) : config.url; 
                config.url = config.url.includes('api/') ? config.url : `/${tenant}/api/${requestURL}`;
                config.headers.Authorization = `Bearer ${token}`;
                config.user = user;
                return config;
                }  
        );
        upSideAuthRequest.interceptors.response.use(
            (response) => response, // this is for all successful requests.
            (error) => errorInterceptor(error), // handle the request
          );
    }
    // preference
    getApiKey() {
        return upSideAuthRequest.get(`/preference/?code=API_ACCESS_KEY`);
    }
    getUPGPT_COPILOT() {
        return upSideAuthRequest.get(`/preference/?code=UPGPT_COPILOT`);
    }
    // upside
    aiBotDetail(id) {
        return upSideAuthRequest.get(`/chatgpt/upgpt/aibotdetail/${id}`);
    }
    getMeetingRecapList(page,search,pageitem,sortby){
        return upSideAuthRequest.get(`/copilot/upgpt/getmeetingrecaplist/?page=${page}&search=${search}&pageitem=${pageitem}&sortby=${sortby}`);
    }
    createMeetingRecap() {
        return upSideAuthRequest.post(`/copilot/upgpt/getmeetingrecaplist/`);
    }
    deleteMeetingRecap(id) {
        return upSideAuthRequest.delete(`/copilot/upgpt/editdeletemeetingrecap/${id}`);
    }
    editMeetingRecap(data) {
        return upSideAuthRequest.put(`/copilot/upgpt/editdeletemeetingrecap/${data.id}`,data);
    }
    getMeetingRecapDetail(id){
        return upSideAuthRequest.get(`/copilot/upgpt/getmeetingrecapdetail/${id}`);
    }
    giveMeSummary(data,key){
        return upSideAuthRequest.post(`/copilot/upgpt/summarizemeeting/?apikey=${key}`,data);
    }
    opptyList(keyword,id){
        return upSideAuthRequest.get(`/copilot/upgpt/opptsearchlist/?keyword=${keyword}&id=${id}`);
    }
    updateCRM(data,key){
        return upSideAuthRequest.post(`/copilot/upgpt/updatecrm/?apikey=${key}`,data);
    }
    JotAINoteBook(data,key){
        return upSideAuthRequest.post(`/copilot/upgpt/jotainotebook/?apikey=${key}`,data);
    }
    getAiNotebookList() {
        return upSideAuthRequest.get(`/copilot/upgpt/setgetainotebook/`);
    }
    createAiNotebook(data) {
        return upSideAuthRequest.post(`/copilot/upgpt/setgetainotebook/`,data);
    }
    getAiNotebookDeteail(id) {
        return upSideAuthRequest.get(`/copilot/upgpt/getainotebookfielddetail/${id}/`);
    }
    editAiNotebook(data) {
        return upSideAuthRequest.put(`/copilot/upgpt/editdeleteainotebook/${data.id}/`,data);
    }
    editAiNotebookFields(data) {
        return upSideAuthRequest.put(`/copilot/upgpt/editinotebookfields/${data.id}/`,data);
    }
    deleteAiNotebook(id) {
        return upSideAuthRequest.delete(`/copilot/upgpt/editdeleteainotebook/${id}/`);
    }

    AiMeetingMailGenerate(data,key){
        return upSideAuthRequest.post(`/copilot/upgpt/aimeetingmailgenerate/?apikey=${key}`,data);
    }
    AiTasksGenerate(data,key){
        return upSideAuthRequest.post(`/copilot/upgpt/aitaskgenerate/?apikey=${key}`,data);
    }
    AIAnalysisImage(data,key){
        return upSideAuthRequest.post(`/copilot/upgpt/aianalysisimage/?apikey=${key}`,data);
    }
}
export default UpSideService;