import Vue from 'vue';
import Vuex from 'vuex';
import {
  loginUser,
  logoutUser,
  loginUserUpSide
} from '../services/api/auth';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
    isLoggedIn: false,
  },
  mutations: {
    loginSuccess(state, userId) {
      state.user = userId;
      state.isLoggedIn = true;
    },
    logout(state) {
      state.user = null;
      state.isLoggedIn = false;
    },
  },
  actions: {
    login({
      commit
    }, {
      username,
      password,
      tenant,
      loginValiCode,
    }) {
      return loginUser(username, password, tenant, loginValiCode)
        .then(() => {
          commit({
            type: 'loginSuccess',
            username, tenant
          });
          return Promise.resolve();
        }).catch((error) => {
          commit({
            type: 'logout'
          });
          return Promise.reject(error);
        });
    },
    // login for UpSide
    loginUpSide({
      commit
    }, {
      username,
      password,
      tenant,
      loginValiCode,
    }) {
      return loginUserUpSide(username, password, tenant, loginValiCode)
        .then((response) => {
          commit({
            type: 'loginSuccess',
            username, tenant
          });
          return response;
        }).catch((error) => {
          commit({
            type: 'logout'
          });
          return Promise.reject(error);
        });
    },
    logout({
      commit
    }) {
      logoutUser();
      commit('logout');
    },
  },
  modules: {},
});